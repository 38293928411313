
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('/assets/fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('/assets/fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('/assets/fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/roboto-v20-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Roboto Black'), local('Roboto-Black'),
       url('/assets/fonts/roboto-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/roboto-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-900.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/roboto-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/roboto-v20-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}


html {
  font-family: 'Roboto', sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-size: 13px;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body.electron {
  overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6,p {
  line-height: 1.45;
}

pre {
	font-family: 'Roboto', sans-serif;
}

.inline-link {
  color: rgba(65, 190, 187, 1);
  text-decoration: none;
}

.inline-link:hover {
  text-decoration: underline !important;
}

@keyframes animate-stripes {
  0% { background-position: 60px 0; }
  100% { background-position: 0 0; }
}

/*
 * from frontend react index.css
 */


 .login {
	min-height: 100vh;
	position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 48px;
    align-items: left;
    padding: 24px 12px;
    box-sizing: border-box;
}

@media only screen and (max-device-width: 500px) {
    .login {
        align-items: center;
        margin: 0;
        padding: 0px;
    }
}

.login-background {
    width: 100%;
	height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;
}


.login-background:before {
    content: '';
	bottom: -12px;
	top: -12px;
	left: -12px;
	right: -12px;
    position: absolute;
    display: block;
    background: rgb(11,114,136);
    background: linear-gradient(149deg, rgba(11,114,136,1) 0%, rgba(162,200,202,1) 90%);
    background-position: center center;
 
    background-size: cover;

    background-image: url('/assets/media/workdesk.png');

}

@media only screen and (max-width: 500px),
(max-device-width: 850px) and (orientation: landscape)
{
    .login-background:before {
        content: '';
        bottom: -12px;
        top: -12px;
        left: -12px;
        right: -12px;
        position: absolute;
        z-index: 1;
        display: block;
        background: white;
        background-color: white;
    }

    .login {
        margin-left: 0px;
        padding: 0px;
    }
}

.login-inner {
	max-width: 632px;
	width: 100%;
	background: #fff;
	border-radius: 4px;
    box-shadow: 0px 16px 21px rgba(0, 50, 60, 0.09);
    box-sizing: border-box;
    flex-shrink: 0;
    z-index: 1;
}

.login-inner h1 {
    font-weight: 900;
    color: rgba(0, 50, 60, 0.54);
    letter-spacing: -1.43px;
    font-size: 46px;
    line-height: 46px;
}


.login-inner h2 {
    font-weight: 500;
    color: rgba(65, 190, 187, 1);
    margin: 0;
    text-align: center;
    letter-spacing: 0px;
    font-size: 23px;
    line-height: 27px;
}

.login-text {
    color: rgba(0, 50, 60, 0.54);
    font-size: 16px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 9px;
    max-width: 474px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}


.login-heading {
    margin-bottom: 13px;
}

.login-heading h1 {
    margin-bottom: 23px;
}

.login-heading img {
    width: 0px;
}


.login-heading-success h1 {
    text-align: center;
    margin-bottom: 5px;
    max-width: 396px;
    margin-left: auto;
    margin-right: auto;
}

.login-heading-success h2 {
    text-align: center;
    margin-bottom: 100px;
    max-width: 396px;
    margin-left: auto;
    margin-right: auto;
}

.login-text-info {
    text-align: center;
    letter-spacing: 0px;
    color: #00323C89;
    opacity: 1;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: auto;
    max-width: 315px;
}

.login-inner-content {
    color: rgba(0, 50, 60, 0.54);
    padding-left: 24px;
    padding-right: 24px;
}

.login-inner-content-header-success {
    margin-bottom: 30px;
    margin-top: 125px;
}

.login-inner-content-header {
    margin-bottom: 18px;
    padding-top: 42px;
    display: flex;
    justify-content: space-between;
}

.login-header-logo {
    width: 174px;
    height: 70px;
}


.login-inner-content-list {
    text-align: left;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0px;
    color: rgba(65, 190, 187, 1);

    font-weight: 500;
    max-width: 370px;
    margin-left: 8px;
    display: flex;
    align-items: top;
    margin-top: 8px;
    padding-bottom: 12px;
}

.login-text-check-inbox {
    margin-bottom: 220px;
}

@media only screen and (min-width: 500px) {
    .login-inner-content {
        padding-left: 48px;
        padding-right: 48px;
    }

    .login-inner-content-header-success {
        margin-bottom: 96px;
    }
}

.login-textfield:focus,
.login-textfield:hover {
    border-color: rgba(0, 50, 60, 0.54) !important;
}

.login-textfield-has-error:focus,
.login-textfield-has-error:hover {
    border-color: #D84315 !important;
}

.login-checkbox-label {
    color: rgba(0, 50, 60, 0.54);
    font-size: 14px;
}

.login-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    letter-spacing: 0px;
    font-size: 14px;
    margin-left: 12px;
    margin-right: 12px;
}


.login-footer-text {
    display: block;
    font-size: 16px;
    color: rgba(0, 50, 60, 0.54);
    margin-bottom: 12px;   

}

.register-email-btn-div {
    width: 267px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.register-email-tf-email {
    margin-bottom: 27px;
}

.action-wrapper-stack {
    margin-top: 0px;
}

.action-wrapper-nostack {
    margin-bottom: 9px;
    margin-top: 20px;
}


/* Tablet or small browser size */
@media only screen and (min-device-width: 500px) and (max-device-width: 1024px) and (orientation: portrait),
(min-device-width: 1024px) and (max-device-width: 1200px) and (orientation: landscape),
(max-width: 1024px)
 {
     .login-inner {
        margin-left: auto;
        margin-right: auto;
     }
    .login {
        align-items: center;
        margin-left: 0px;
    }
}

/* mobile */
@media only screen and (max-width: 500px),
(max-device-width: 850px) and (orientation: landscape)
{
    .login {
        justify-content: normal;
    }
    .login-inner {
        padding-left: 0px;
        padding-right: 0px;
        font-size: 12px;
        max-width: 100%;
        box-shadow: none;
    }

    .login-header-logo {
        width: 159px;
        height: 55px;
    }

    .login-heading {
        margin-bottom: 6px;
    }

    .login-inner-content-header {
        margin-bottom: 8px;
        padding-top: 10px;
    }

    .login-heading h1 {
        margin-bottom: 12px;
        margin-top: 12px;
    }

    .login-heading img {
        width: 100%;
    }

    .login-inner h1 {
        font-weight: 900;
        letter-spacing: -1.43px;
        font-size: 28px;
        line-height: 28px;
    }

    .login-inner-content-list {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0px;
        margin-top: 8px;
        padding-bottom: 6px;
        font-weight: 450px;
    }

    .login-checkbox-label {
        font-size: 14px;
    }

    .register-email-tf-email {
        margin-bottom: 14px;
    }

    .login-checkbox-wrapper {
        margin-bottom: 10px;
        font-size: 12px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .action-wrapper-nostack {
        margin-bottom: 8px;
        margin-top: 0px;
    }

    .login-text {
        font-size: 14px;
        margin-top: 4px;
    }

    .login-inner-content-header-success {
        margin-bottom: 30px;
        margin-top: 70px;
    }

    .login-heading-success h2 {
        margin-bottom: 70px;
    }
    .login-text-check-inbox {
        margin-bottom: 0px;
    }
    
}

/* very small display-size */
@media only screen and (max-width: 300px) {
    .register-email-btn-div {
        width: 220px;
    }
    .login {
        align-items: center;
        margin-left: 0px;
    }
    .login-inner h1 {
        font-weight: 900;
        letter-spacing: -1.43px;
        font-size: 26px;
        line-height: 26px;
    }
    .login-inner-content-list {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0px;
        margin-top: 8px;
        padding-bottom: 6px;
        font-weight: 450px;
    }
    .login-text {
        font-size: 12px;
        margin-top: 4px;
    }
}


